.credential {
  padding: 80px 30px 0;
}
.calendar-btn {
  right: 10px;
  top: 45px;
}
.vertical {
  /* display: none !important; */
}
.credential .right-data {
  background-image: url(../images/svg/data-background.svg);
  background-repeat: no-repeat;
  width: 100%;
  /* height: 225px; */
}
@media only screen and (max-width: 1023px) {
  .credential .right-data {
    margin-top: 10px;
  }
}
@media screen and (max-width: 768px) {
  .credential {
    padding: 130px 15px 0;
  }
}
@media only screen and (max-width: 767px) {
  .credential .credential-scroll {
    height: 100%;
  }
}
