@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");

.App {
  position: relative;
  background-color: #073d83;
  background-image: url(../../../src/assets/images/login-bg.png);
  background-size: cover;
  background-position: center;
}

.bannerImage {
  height: 100vh;
}

.edexaLogo {
  position: absolute;
  top: 3%;
  left: 4%;
}

.content {
  position: absolute;
  color: #fff;
  text-align: left;
  bottom: 20%;
  left: 6%;
}

p.mainContainer__contentSlider {
  font-size: 27px;
  margin-bottom: 30px;
}

.content h2 {
  font-size: 49px;
  font-weight: 400;
  width: 930px;
  line-height: 62px;
  margin-bottom: 30px;
}

.mainContainer__contentUnderHeading {
  font-size: 16px;
  opacity: 1;
  width: 60%;
}

/* Login Form */

.formSection {
  width: 500px;
  height: 410px;
  max-height: 550px;
  position: absolute;
  top: 0;
  bottom: 20%;
  right: 6%;
  padding-top: 33px;
  background-color: #fff;
}

.small-formSection {
  width: 500px;
  height: 350px;
  position: absolute;
  top: 0;
  bottom: 20%;
  right: 6%;
  padding-top: 33px;
  background-color: #fff;
}

.formSection__innerDiv h2 {
  font-size: 30px;
  text-align: center;
  margin-top: 6px;
  margin-bottom: 8px;
  font-weight: 700;
  color: #000;
}

.mainContainer__registerFormPara {
  color: #4c4f53;
  font-size: 16px;
  text-align: center;
  margin-bottom: 15px;
}

.mainContainer__emailInputFieldDiv input,
.mainContainer__passwordFields input {
  padding: 13px;
  width: 420px;
  border: 1px solid #dadce0;
  border-radius: 5px;
  outline: none;
  margin-bottom: 7px;
}

input.mainContainer__password {
  margin-bottom: 10px;
}

input.mainContainer__email {
  margin-bottom: 13px;
}
.error-msg-set {
  margin-left: 40px;
}
input::placeholder {
  color: #868686;
  font-size: 14px;
}
.checkBoxDiv {
  text-align: left;
  margin-bottom: 6%;
  position: relative;
  margin-top: 2%;
}

input[id="customCheck1"] + label {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #dadce0;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  margin-left: 42px;
}

input[id="customCheck1"]:checked + label:after {
  content: "\2714";
  font-size: 14px;
  position: absolute;
  top: -3px;
  left: 3px;
}

input[id="customCheck1"] {
  display: none;
}

.mainContainer__rember {
  margin-left: 2%;
  font-size: 14px;
  color: #4c4f53;
  position: absolute;
  margin-bottom: 35px;
}

a.forgotpassword {
  color: #073d83;
  font-size: 14px;
  font-weight: 700;
  margin-left: 55%;
  position: absolute;
}

.mainContainer__submit {
  padding: 15px;
  width: 420px;
  background: #073d83 0% 0% no-repeat padding-box;
  color: #fff;
  border-radius: 5px;
  border: 0;
  margin-bottom: 15px;
  outline: none;
}

.mainContainer__submit__if__errorOccuered {
  padding: 15px;
  width: 420px;
  background: #073d83 0% 0% no-repeat padding-box;
  color: #fff;
  border-radius: 5px;
  border: 0;
  margin-bottom: 30px;
  outline: none;
  margin-top: 5px;
}

@media (max-width: 1680px) {
  img.bannerImage {
    height: 100vh;
  }

  .content {
    bottom: 38%;
    left: 4%;
  }

  .content h2 {
    font-size: 40px;
    font-weight: 400;
    width: 930px;
    line-height: 50px;
    margin-bottom: 30px;
  }
}

@media (max-width: 1399px) {
  p.mainContainer__contentSlider {
    font-size: 24px;
    margin-bottom: 30px;
  }

  .content h2 {
    font-size: 35px;
    font-weight: 400;
    width: 450px;
    line-height: 50px;
    margin-bottom: 30px;
  }

  .mainContainer__contentUnderHeading {
    font-size: 16px;
    opacity: 1;
    width: 450px;
  }
}

@media (max-width: 1399px) {
  p.mainContainer__contentSlider {
    font-size: 27px;
    margin-bottom: 20px;
  }

  .content h2 {
    font-size: 27px;
    font-weight: 400;
    width: 400px;
    line-height: 43px;
    margin-bottom: 20px;
  }

  .mainContainer__contentUnderHeading {
    font-size: 16px;
    opacity: 1;
    width: 400px;
  }

  .mainContainer__emailInputFieldDiv input,
  .mainContainer__passwordFields input {
    padding: 13px;
    width: 420px;
    border: 1px solid #dadce0;
    border-radius: 5px;
    outline: none;
    
  }
}

@media (max-width: 775px) {
  .formSection {
    width: 87%;
    padding: 20px;
    margin-top: 20vh !important;
  }

  .mainContainer__submit {
    width: 100%;
    margin-left: 0;
  }

  .mainContainer__email {
    width: 100% !important;
  }

  .mainContainer__password {
    width: 100% !important;
  }
  .content {
    display: none;
  }
  .error-msg-set {
    margin-left: 0;
  }
}

.eye-login {
  position: absolute;
  right: 55px;
  top: 18px;
  font-size: 18px;
  color: gray;
}

.pre-login-page .input-group .custom-input {
  height: 38px;
}
.form-control.is-invalid:focus{
  box-shadow: none !important;
}