@import "bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import "react-datepicker/dist/react-datepicker.css";
@import "assets/css/fontawesome.min.css";
@import "assets/css/common.css";
@import "assets/css/form-element.css";
@import "assets/css/header.css";
@import "assets/css/sidebar.css";
@import "assets/css/footer.css";
@import "assets/css/dashboard.css";
@import "assets/css/holders.css";
@import "assets/css/issue-credential.css";
@import "assets/css/credentials.css";
@import "assets/css/information.css";
@import "assets/css/reivew.css";
@import "assets/css/listing.css";

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: Lato;
  background-color: #f8f8f8;
}

.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-wrapper:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

/* react date picker style */
/* disable black outline when in focus-visible state */
.react-datepicker-wrapper input:focus-visible{
  outline: none !important;
}
.react-datepicker-popper {
  z-index: 9999999;
}