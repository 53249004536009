.logoContainer {
    min-width: 100px;
    min-height: 50px;
    max-height: 50px;
    max-width: 120px;
    border: 1px dotted #ced4da;
}

.logoContainer img {
    max-height: 50px;
    object-fit: contain;
}

.info-image {
    max-width: 200px;
}