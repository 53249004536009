.sidebar {
  background-color: #ffffff;
  /* padding: 20px 20px 20px 0; */
  padding: 20px 5px 20px 0;
  position: absolute;
  left: 0;
  height: calc(100vh - 60px);
  z-index: 5;
  border-right: 1px solid #dadce0;
  transition: all 0.5s;
  overflow: auto;

}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background-color: #007de4;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

.sidebar.hide {
  min-width: 80px;
}
/* .sidebar.show {
 min-width: 270px;
} */

.sidebar.show {
  min-width: 285px;
 }




.sidebar-main .sidebar-menu {
  padding: 15px 20px;
  border-radius: 0 10px 10px 0;
  overflow: hidden;
  z-index: 1;
  margin-bottom: 10px;
}

.dropdown-siderbar-hover:hover {
  color: black;}
.submenu {
  padding: 15px 5px 15px 35px;
  /* color: #212529; */
}

.submenu:hover {
  padding: 15px 5px 15px 35px;
  /* color: #212529; */
}

.sidebar-main .sidebar-menu.menu-active .menu-icon {
  fill: #ffffff;
}

.sidebar-main .sidebar-menu.menu-active::before {
  background-color: #007de4;
  visibility: visible;
}

.sidebar-main .sidebar-menu.menu-active:hover::before {
  background-color: #007de4;
  visibility: visible;
}

.sidebar-main .sidebar-menu.menu-active:hover {
  color: white;
}

.sidebar-main .hover-color-change:hover {
  color: black;
}

.sidebar-main .sidebar-menu .menu-icon {
  max-width: 20px;
  max-height: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.sidebar-main .sidebar-menu::before {
  position: absolute;
  content: "";
  z-index: -1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  visibility: hidden;
  /* background-color: #007de4; */
  transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  -ms-transition: 0.2s ease-in;
  -moz-transition: 0.2s ease-in;
  -webkit-transition: 0.2s ease-in;
}

.sidebar-main .sidebar-menu:hover::before {
  /* width: 100%; */
  visibility: visible;
  background-color: #e8f0fe;
}

.sidebar-main .sidebar-menu:hover {
  color: black;
}

.sidebar-main .sidebar-menu.menu-active::before {
  width: 100%;
}

@media (max-width: 768px) {
  .sidebar {
    height: calc(100vh - 116.03px);
  }
  .sidebar-main .sidebar-menu {
    padding: 10px 15px;
  }
  .sidebar-main .sidebar-menu .menu-icon {
    max-width: 18px;
    max-height: 18px;
  }
}

@media (max-width: 1199px) {
  .sidebar {
    left: -270px;
  }
  .sidebar.hide {
    left: 0;
  }
  .sidebar .sidebar-bg-layer {
    position: fixed;
    content: "";
    height: 100%;
    width: 100%;
    /* background-color: #007de4;
        opacity: 0.3; */
    left: 100%;
    top: 0;
    z-index: -1;
    /* transition: 0.3s ease-in;
        -o-transition: 0.3s ease-in;
        -ms-transition: 0.3s ease-in;
        -moz-transition: 0.3s ease-in;
        -webkit-transition: 0.3s ease-in; */
  }
  .sidebar.hide .sidebar-bg-layer {
    left: 250px;
  }
}