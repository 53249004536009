.app-icon-wrapper {
  /* min-width: 120px; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 130px;
}
.app-icon-wrapper.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.app-icons {
  height: 30px;
  width: 30px;
  padding: 4px;
  align-items: center;
}
