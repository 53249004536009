.confimation-modal {
  max-width: 450px !important;
  min-height: 150px !important;
  border-radius: 4px !important;
  overflow: hidden;
}
.confimatio-button {
  background-color: #fff;
  font-size: 18px;
  margin: auto;
}
