input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.custom-input {
  border-radius: 5px;
  padding: 10px 20px;
  transition: all 0.3s;
}

.custom-input::placeholder {
  color: #868686;
}

.custom-input,
.custom-input ~ .input-group-append .input-group-text {
  border: 1px solid #dadce0;
}

.custom-input:focus,
.custom-input:focus ~ .input-group-append .input-group-text {
  box-shadow: none;
  border-color: #007de4;
}
.custom-input:focus-visible {
  outline: none;
}

.custom-input ~ .input-group-append {
  margin-left: 0 !important;
}

.custom-input ~ .input-group-append .input-group-text {
  border-left: none;
  border-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  position: relative;
  width: 50px;
  transition: all 0.3s;
}

.custom-input ~ .input-group-append .input-group-text::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: calc(100% - 20px);
  background: #dadce0;
}

.custom-input.icon-right {
  border-right: 0;
}

.custom-radio.form-check label {
  cursor: pointer;
}

/* Radio */

.custom-radio .form-check-input {
  width: 10px;
  min-width: 10px;
  height: 10px;
  border-color: transparent;
  left: 0px;
  position: absolute;
}

.custom-radio .form-check-input:checked {
  background: #007de4;
  border-color: #007de4;
}

.custom-radio.blue .form-check-input:checked {
  background: #007de4;
}

.custom-radio .outer-circle {
  position: absolute;
  content: "";
  left: -5px;
  top: -1px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 2px solid #dadce0;
}

/* Button */

.gray-border-btn {
  border: 1px solid #dadce0;
}

.gray-border-btn:hover,
.gray-border-btn:focus {
  border-color: #dadce0;
  background: #f9f9f9;
}

/* Dropdown */

.custom-select {
  color: #4c4f53 !important;
  position: relative;
  border-radius: 5px;
  border-color: #dadce0;
  padding: 10px 20px;
}

.custom-select:focus {
  box-shadow: none;
  border-color: #007de4;
}

.custom-select::after {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

/* Stepper */

.custom-stepper * {
  font-weight: 400 !important;
  vertical-align: top;
}

.custom-stepper a {
  line-height: 20px !important;
  max-width: 86px;
  margin: auto;
  vertical-align: middle;
  display: flex !important;
  justify-content: center;
  font-size: 14px !important;
}

.custom-stepper > div > div > div > div {
  background: none !important;
  border-color: #007de4;
}
.custom-stepper > div > div > div > div > a {
  position: relative;
  height: 100%;
}

.custom-stepper > div > div > div > div > a > img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.custom-stepper > div > div > div > div:nth-child(2) {
  max-width: 80px;
  margin: 0 auto;
}

.custom-stepper > div > div > div > div > span {
  height: 100%;
  display: block;
  line-height: 0 !important;
  background-image: url(../images/svg/current-step.svg);
  position: absolute;
  width: 30px;
  height: 100%;
  background-repeat: no-repeat;
  color: transparent !important;
}

@media (max-width: 1680px) {
  .custom-input ~ .input-group-append .input-group-text {
    width: 44px;
  }
}

@media (max-width: 1399px) {
  .custom-input ~ .input-group-append .input-group-text {
    width: 38px;
  }
}
