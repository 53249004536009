
.notification-info{
    display: flex; 
    align-items: center;
    width: 100%;
  }
  .notification-text{
    width: 11px; 
    height: 11px; 
    border-radius: 50%; 
    overflow:hidden; 
    margin-right: 10px;
  }
  
  .align-notification{
      align-items: center;
  }
  .scrollbar-notification{
    max-height: 60vh !important;
    overflow: hidden;
  }

  .trackVertical{
    right: 0;
    top: 0;
    height: 100% !important;
    width: 2px !important;}
  .trackVertical div{
    background-color: #073D83 !important;
    width: 2px !important

  }
