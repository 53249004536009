#root {
    overflow-x: hidden !important;
}

.public-verification .card-header {
    border-bottom: 0px !important;
    background-color: transparent !important;
}

.public-verification .card {
    border: 0px !important;
    box-shadow: 0px 1px 20px 0px #80808036 !important;
}


/* close button */

.close-btn.public-verify {
    top: 20px;
    right: 20px;
}