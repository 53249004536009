.status-radio-buttons {
    width: fit-content;
    height: 40px;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    z-index: 4;
}
.sub-radio-buttons input {
    z-index: 5;
}
.status-radio-buttons input:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    z-index: -1;
}
.status-radio-buttons input:checked::before {
    background: rgba(0, 125, 228, 0.1);
}
@media screen and (max-width: 1293px) {
    .status-buttons {
        padding-top: 20px;
        padding-left: 25px;
    }
}
@media screen and (max-width: 717px) {
    .status-radio-buttons {
        margin-top: 0px;
        margin-bottom: 10px;
    }
    .status-radio-buttons ~ .btn {
        margin-top: 10px;
    }
}
