.kyc-box {
  background-color: #fff;
  /* background-color: #2d7de4; */
  border: 1px solid #dadce0;
}

.kyc-box .box {
  width: 19%;
  height: 110px;
  padding: 30px;
  border-radius: 5px;
  cursor: pointer;
}

.kycDetailsTitleDiv {
  position: relative;
  margin-bottom: 1.2%;
}

.kycDetailsTitleDiv button {
  position: absolute;
  bottom: 5px;
}

.approveRejectBtn {
  position: absolute;
  right: 9%;
  bottom: -20%;
}

.main-title {
  color: #0d0f12;
  font: normal normal bold 30px/36px Lato;
  text-transform: capitalize;
}

.no-first-letter-capital-title {
  color: #0d0f12;
  font: normal normal bold 30px/36px Lato;
}

.kycDetailPageTitle {
  text-align: center;
}

.sub-title {
  color: #0d0f12;
  font: normal normal bold 20px/26px Lato;
}

.link-more {
  color: #007de4;
  font: normal normal 600 14px/22px Lato;
  cursor: pointer;
}

/* APPROVE OR REJECT BUTTONS */
