.dashboard {
  padding: 30px;
}

.list-box {
  background-color: #fff;
  padding: 20px;
}

.list-box .box {
  width: 24%;
  height: 110px;
  padding: 30px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s;
  box-shadow: 0 1px 1px rgb(0 0 0 / 0.2);
}
.list-box .box:hover{
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.list-box .box:nth-child(1) {
  background-color: #cffbf8;
}

.list-box .box:nth-child(2) {
  background-color: #fcd8da;
}

.list-box .box:nth-child(3) {
  background-color: #f2e8ff;
}

.list-box .box:nth-child(4) {
  background-color: #ddeffc;
}

.list-box .box:nth-child(5) {
  background-color: #e5fcdd;
}

.list-box .box:last-child {
  background-color: #fbf7db;
}

.dashboard-data {
  /* height: 500px; */
}

.table-section {
  /* border: 1px solid #DADCE0; */
  border-radius: 5px;
}

.table-section thead {
  background-color: #e8f0fe;
  position: sticky;
  top: 0;
  z-index: 1;
}

.table-section tbody tr {
  border-bottom: 1px solid #dadce0;
}

.table-section thead th,
tbody td {
  border: none;
}

.table-section thead th,
tbody th,
tbody td {
  padding: 17px !important;
}

.table-section .status-data {
  padding: 17px 17px 17px 35px !important;
}

.thumb-horizontal,
.thumb-vertical {
  background-color: #007de4;
  border-radius: 10px;
}

.table-section .holder-profile {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.table-section .holder-profile img {
  width: 100%;
  height: 100%;
}

.table-section .status {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  left: 17px;
  top: 22px;
}

.table .active {
  background-color: #007de4;
}

.table .deactive {
  background-color: #ea2027;
}

.table .pending {
  background-color: #eaac20;
}

.table .inprocess {
  background-color: #f75c1e;
}

.table .InProgress {
  background-color: #eaac20;
}
.table .Declined {
  background-color: #ea2027;
}
.table .Scheduled {
  background-color: #20eadb;
}
.table .Completed {
  background-color: #28c76f;
}
.table .Approved {
  background-color: #2d7de4;
}

.tips-box {
  height: 100%;
  padding: 30px 20px;
  position: relative;
}

.tips-box button {
  position: absolute;
  bottom: 30px;
}

.dashboard-coutns {
  width: 49% !important;
}

.dashboard-coutns2 {
  width: 49.3% !important;
}
.admin-dashboard {
  width: 49%;
}

.admin-dashboard-complete-width {
  width: 100%;
}

.admin-dashboard2 {
  width: 100%;
}

@media only screen and (max-width: 1024px) {
  .list-box {
    justify-content: left !important;
  }
  .list-box .box {
    width: 30%;
    margin: 20px 20px 0 0;
  }
}

@media only screen and (max-width: 768px) {
  .dashboard {
    padding: 30px 15px;
  }
  .list-box {
    justify-content: space-between !important;
  }
  .list-box .box {
    width: 47%;
    margin: 10px 0 0;
  }
  .tip {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .dashboard-data {
    flex-wrap: wrap;
  }
  .list-box .box {
    width: 100%;
    margin: 10px 0 0;
  }

  .dashboard-coutns {
    width: 100% !important;
    margin-top: 10px;
  }

  .dashboard-coutns2 {
    width: 100% !important;
  }
  .admin-dashboard {
    width: 100%;
  }
}
