.import-voters-footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.download-file-button {
  /* background-color: #1bc5bd; */
  background-color: #007de4;

  border: 1px solid;
  display: flex;
}
.download-file-button p {
  font-weight: 500;
}
.button-icon {
  width: 20px;
  padding-top: 4px;
  margin-right: 2px;
}
.upload-voters-button {
  background-color: #007de4;
  border: 1px solid;
  display: flex;
}
.important-notes-list span {
  font-weight: 700;
  cursor: pointer;
}
.modal-body-section {
  margin-top: 10px;
}
.selected-file {
  margin: 4px 16px
}
.upload-spinner {
  width: 30px;
  padding-left: 4px;
}

input::-webkit-calendar-picker-indicator {
  cursor: pointer;
}